import Menu from '../Components/Menu.js';
import ContactCard from '../Components/ContactCard.js';
import '../Styles/Contact.css';
import Divider from '../Components/Divider.js';
import NewMenu from "../Components/NewMenu";
import OurPartners from '../Components/OurPartners.js';
const Contact = ()=>{

    return (
        <div>
          <NewMenu />
          <Divider/>
          <div>
          

          </div>
        <div className="contactDiv" style={{height:"auto",textAlign:"center"}}>
            <h2 ><u>Connect With Us-</u></h2>
            <div className="contactBox" >
          <div className="ContactCard">
          <ContactCard  title="Address-" value="GF 1 Ground Floor, Subhash Chandra Bose Complex, Tulsidas Marg, Chowk, Lucknow" link="https://maps.app.goo.gl/ogT1dBS3kHj1zGhdA"/>

          </div>
          <div className="ContactCard">
          <ContactCard  title="Mobile Number-" value="+919811377954" link="tel:+919811377954"/>

          </div>
          <div className="ContactCard">
          <ContactCard  title="Email Id-" value="Snehc.sales@gmail.com " link="mailto:Snehc.sales@gmail.com"/>

          </div>
            
            
            
        

        </div>
        
      </div>
        
    </div>
    );
}
export default Contact;