import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import '../Styles/AboutUs.css';
function AboutOwner() {
  return (
    <Card className="text-center-owner" >
      <Card.Header className="ACheader" >About Us</Card.Header>
      <Card.Body>
        <Card.Title className="ACTitle" >Prafful Singh</Card.Title>
        <Card.Text className="ACText" >
        Proprietor of firm Sneh Communications, after completing Bachelors in Technology(Electronics and Communications) has worked
          in major MNCs like Huawei Telecommunications and Cisco Networks Inc. as Network Consultant for Seven years(2009-2016) before
          starting his own venture catering to needs of hospitals and diagnostics centers and trying to bridge gap between Manufacturers
          and Centers. Sneh Communications has grown from ground to an average $3.2 million USD turnover in last six years
        </Card.Text>
        {/* <Button variant="primary">Go somewhere</Button> */}
      </Card.Body>
      {/* <Card.Footer className="text-muted">2 days ago</Card.Footer> */}
    </Card>
  );
}

export default AboutOwner;