import Divider from '../Components/Divider.js';
import NewMenu from "../Components/NewMenu";
import NewFooter from "../Components/NewFooter";
import {useEffect} from 'react';
import '../Styles/readMore.css';
const ReadMore = ()=>{
    useEffect(()=>{
        window.scrollTo(0,0);
    },[]);
    useEffect(()=>{
        const observer = new IntersectionObserver((entries)=>{
          entries.forEach((e)=>{
            if(e.isIntersecting)
            {
             e.target.classList.add("VisionAnime");
            }
            // e.target.classList.remove("partnerCardAnime");
    
          });
        }
        );
        observer.observe(document.querySelector('.visionDiv'));
      },[]);
      useEffect(()=>{
        const observer = new IntersectionObserver((entries)=>{
          entries.forEach((e)=>{
            if(e.isIntersecting)
            {
             e.target.classList.add("ValueAnime");
            }
            // e.target.classList.remove("partnerCardAnime");
    
          });
        }
        );
        observer.observe(document.querySelector('.readMore-innerValues'));
      },[]);



    return(
        <div>
            <NewMenu/>
            <Divider/>
            <div className='readMore-About'>
               
            </div>
            <div className='readMore-innerAbout'>
                    <div className='alignmentDiv'>
                        <div className='readMore-innerAbout-heading'>About Company</div>
                        <div className='readMore-innerAbout-content'>Sneh Communcications has been providing medical equipments, dignostics kits, radiology equipments, blood bank related products and services 
                             to various private and government hospitals and dignostics centers around the states of Northern India
                        </div>
                    </div>
            </div>
            <div className="visionDiv">
                Our Vision
                <p>Reaching People And Touching Lives Globally As A Leading Provider Of Valued Medicines</p>
            </div>
            <div className='readMore-Values'>
               
            </div>
            <div className='readMore-innerValues'>
                <div className='alignmentDivValues'>
                    <h2>Our Values</h2>
                    <p>Our values represent our promise to our stakeholders.</p>
                    <h3>Quality</h3>
                    <p>Get it right the first time</p>
                    <h3>Reliability</h3>
                    <p>Maintain efficiency & discipline in all processes & systems and fulfil the promises made to stakeholders</p>
                    <h3>Trust</h3>
                    <p>Be transparent in dealings</p>
                    <h3>Consistency</h3>
                    <p>Endeavour to bring new products to the market & consistently deliver value to stakeholders</p>
                </div>
            </div>
            
            <NewFooter/>
        </div>
    );
}

export default ReadMore;