import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import '../Styles/home.css';
function ClientsCard(props) {
  return (
    <Card className="text-center" >
      <Card.Header style={{fontWeight:"Bold ",fontSize:"15px"}}>{props.title}</Card.Header>
      <Card.Body>
        <Card.Title style={{color:"green"}}>{props.desc}</Card.Title>
        {/* <Card.Text style={{color:"black",fontSize:"12px"}}>
         
        </Card.Text> */}
        {/* <Button variant="primary">Go somewhere</Button> */}
      </Card.Body>
      {/* <Card.Footer className="text-muted">2 days ago</Card.Footer> */}
    </Card>
  );
}

export default ClientsCard;