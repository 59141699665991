import Card from 'react-bootstrap/Card';

function OurPartners(props) {
  return (
    <Card style={{borderWidth:"4px",backgroundColor:"rgb(6, 23, 107)",marginTop:"50px"}} >
      <Card.Header style={{fontSize:"25px",color:"white",textAlign:"center"}} >{props.text}</Card.Header>
      
    </Card>
  );
}

export default OurPartners;