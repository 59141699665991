import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import '../Styles/home.css';
function ContactCard(props) {
  return (
    <Card className="text-center" >
      <Card.Header style={{fontWeight:"Bold ",fontSize:"20px"}}>{props.title}</Card.Header>
      <Card.Body>
       <a href={props.link}><Card.Title style={{fontWeight:"Bold ",color:"green"}}>{props.value} </Card.Title></a> 
        {/* <Card.Text style={{color:"black",fontSize:"12px"}}>
         SnehComm@gmail.com
        </Card.Text> */}
        {/* <Button variant="primary">Go somewhere</Button> */}
      </Card.Body>
      {/* <Card.Footer className="text-muted">2 days ago</Card.Footer> */}
    </Card>
  );
}

export default ContactCard;