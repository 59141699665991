import Figure from 'react-bootstrap/Figure';
import jmitra from '../jmitra.png';
function PartnerCard( props) {
  return (
    <a href={props.link} target="_blank"><div style={{height:"auto",width:"200px",margin:"10px",padding:"20px"}}>
    <Figure style={{minWidth:"100px"}}>
        <Figure.Image
            width={171}
            height={180}
            alt="171x180"
            src={props.img}
        />
        <Figure.Caption>
            {/* {props.desc} */}
        </Figure.Caption>
        </Figure>
    </div>
    </a>
  );
}

export default PartnerCard;