import Carousel from 'react-bootstrap/Carousel';
import download1 from '../download1.jpg';
import banner1 from '../newBack.webp';
import banner2 from '../banner2.jpg';
import download from '../download.jpg';
import a from  '../a.jpg';
import '../Styles/Banner.css';
function Banner() {
  return (
    <Carousel >
      <Carousel.Item>
        <img
          className="d-block w-100 BannerImg"
          src={banner1}
          alt="First slide"
        />
        <Carousel.Caption>
          <h1 className="bannerH" >Served 100+ clients</h1>
          <p className="bannerP">We are leading HealthCare products sales and service provider</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 BannerImg"
          src={banner1}
          alt="Second slide"
        />

        <Carousel.Caption>
          <h1 className="bannerH">Trusted By Partners </h1>
          <p className="bannerP">We intend to bridge gap between Hospitals/Pathology centers and Manufacturers</p>
        </Carousel.Caption>
      </Carousel.Item>
      
    </Carousel>
  );
}

export default Banner;