import logo from '../logo.svg';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import download from '../office.jpg';
import style1 from '../style1.css';
import Menu from '../Components/Menu.js';
import Banner from '../Components/Banner.js';
import Footer from '../Components/Footer.js';
import profile from '../Profilejpg.jpg';
import AboutCompany from '../Components/AboutCompany';
import PartnerCard from '../Components/PartnerCard';
import '../Styles/home.css';
import img1 from '../jmitra.png';
import img2 from '../img2.png';
import img3 from '../img3.png';
import img4 from '../img4.jpg';
import img5 from '../img5.png';
import img6 from '../img6.jpg';
import OurPartners from '../Components/OurPartners';
import {useState,useEffect} from 'react';
import NewMenu from "../Components/NewMenu";
import NewFooter from "../Components/NewFooter";
function Home() {
  useEffect(()=>{
    window.scrollTo(0,0);
},[]);
  useEffect(()=>{
    const observer = new IntersectionObserver((entries)=>{
      entries.forEach((e)=>{
        if(e.isIntersecting)
        {
         e.target.classList.add("partnerCardAnime");
         console.log("working"+e);
        }
        // e.target.classList.remove("partnerCardAnime");

      });
    }
    );
    observer.observe(document.querySelector('.partnerCard'));
  },[]);
  
  var [index,setIndex] = useState(0);
  return (
    <div className="Home">
      
      
      <NewMenu/>
      
      <Banner/>

      <div className="AboutCompany" >
        <br/>
        <div className="CompanyImage" >
        <img
          style={{width:"100%"}}
          src={download}
          alt="Ss"
        />
        </div>
        <AboutCompany/>
      </div>    
      <div className='partnerCard' style={{height:"auto",marginTop:"50px",textAlign:"center"}}>
        <OurPartners text="Our Partners" />
        <div style={{display:"flex",marginTop:"50px",flexWrap:"wrap",justifyContent:"space-evenly",backgroundColor:"rgb(248, 236, 242)"}}>
        
        <PartnerCard img={img1} desc="hello" link="https://jmitra.co.in"/>
        <PartnerCard img={img2} desc="hello" link="https://konicaminolta.com"/>
        <PartnerCard img={img3} desc="hello" link="https://www.mylan.in/"/>
        <PartnerCard img={img4} desc="hello" link="https://gccbiotech.net/"/>
        <PartnerCard img={img5} desc="hello" link="https://www.carestream.com/en/us/medical"/>
        <PartnerCard img={img6} desc="hello" link="https://www.roche.com/"/>


        

        </div>
        
      </div>
      <NewFooter/>
    </div>
  );
}

export default Home;
