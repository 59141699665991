import Menu from '../Components/Menu.js';
import profile from '../profile.jpg';
import AboutOwner from '../Components/AboutOwner.js';
import download from '../download.jpg';
import Footer from '../Components/Footer.js';
import '../Styles/AboutUs.css';
import Divider from '../Components/Divider.js';
import NewMenu from "../Components/NewMenu";
import NewFooter from "../Components/NewFooter";
import {useEffect} from 'react';
const About = ()=>
{
    useEffect(()=>{
        window.scrollTo(0,0);
    },[]);
    return(
        <div>
            <NewMenu/>
            <Divider/>
            <div className="AboutUs" >
                <div className="ProfileImage" ></div>
                <AboutOwner/>
            </div>    
            <NewFooter/>
        </div>
    );
}
export default About;
