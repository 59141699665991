import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route , useHistory} from "react-router-dom";
import download from './download.jpg';
import style1 from './style1.css';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Menu from './Components/Menu.js';
import Banner from './Components/Banner.js';
import Footer from './Components/Footer.js';
import profile from './Profilejpg.jpg';
import Home from './Screens/Home.js';
import About from './Screens/AboutUs.js';
import Contact from './Screens/Contact';
import Clients from './Screens/Clients';
import ReadMore from './Screens/readMore';
function App() {
  return (
    <BrowserRouter>
    <Routes>

      <Route path="/" element ={<Home/>}/>
      <Route path="/about" element ={<About/>}/>
      <Route path="/clients" element ={<Clients/>}/>
      <Route path="/contact" element ={<Contact/>}/>
      <Route path="/readMore" element ={<ReadMore/>}/>
    </Routes>
    
    {/* <div className="App">
      
      
      <Home/>
    </div> */}
    </BrowserRouter>
  );
}

export default App;
