import logo1 from '../logo.jpg';
import ham from '../ham.png';
import '../Styles/NewMenu.css';
import {useState} from 'react';
import {useNavigate} from "react-router-dom";
const NewMenu = ()=>{
    var [menuMobFlag,setMobFlag] = useState(true);
    var navigate = useNavigate();
    function home()
    {
        navigate("/");
    }
    function about()
    {
        navigate("/about");
    }
    function clients()
    {
        navigate("/clients");
    }
    function contact()
    {
        navigate("/contact");
    } 
    function mobFlagChange()
    {
        setMobFlag(!menuMobFlag);
    }
    return(
        <div style={{height:"70px"}}>
        <div className="newMenuMainDiv" style={{display:"flex",justifyContent:"space-around",height:"70px",lineHeight:"70px"}}>
            <div style={{display:"flex"}}><div>
            <img className="imgLogo" style={{backgroundRepeat:"repeat"}}
              src={logo1} />
              </div><div className="companyName">Sneh Communications</div></div>
            <div className="hamIcon" onClick={mobFlagChange}>
            
            </div>
            <div className="desktopMenu" style={{display:"flex",width:"400px",justifyContent:"space-evenly"}}>
                <div className="desktopMenu menuItem" onClick={home}>Home</div>
                <div className="desktopMenu menuItem" onClick={about}>About Us</div>
                <div className="desktopMenu menuItem" onClick={clients}>Clients</div>
                <div className="desktopMenu menuItem" onClick={contact}>Contact Us</div>

            </div>
        </div>
            <div className={`mobMenu ${menuMobFlag ? "active1" : "null"}`}>
            <div className="menuItemMob" onClick={home}>Home</div>
                <div className="menuItemMob" onClick={about}>About Us</div>
                <div className="menuItemMob" onClick={clients}>Clients</div>
                <div className="menuItemMob" onClick={contact}>Contact Us</div>
            </div>

        </div>
    );
}

export default NewMenu