import Menu from '../Components/Menu.js';
import ClientsCard from '../Components/ClientsCard';
import '../Styles/Clients.css';
import Divider from '../Components/Divider.js';
import OurPartners from '../Components/OurPartners';
import NewMenu from "../Components/NewMenu";
import NewFooter from "../Components/NewFooter";
const Clients = ()=>{

    return (
        <div>
            <NewMenu/>
            <Divider/>
            <div className="heading" style={{marginTop:"20px"}}>
                <u>Our Clients -</u>
            </div>
            <div className="largeBox">
                <div>
                
                   <div style={{marginBottom:"20px"}}> <OurPartners  text="Private Hospitals"/></div>
                    <div>
                    <ClientsCard title="CHARAK Hospital" desc="300 Beded MultiSpecialtiy Hospital"/>
                    </div>
                    <div className="clientBox">
                    <ClientsCard title="MidLand Hospital" desc="100 Beded MultiSpecialtiy Hospital"/>
                    </div>
                    <div className="clientBox">
                    <ClientsCard title="Globe Hospital" desc="300 Beded MultiSpecialtiy Hospital"/>
                    </div>
                    <div className="clientBox">
                    <ClientsCard title="Apollo Hospital" desc="500 Beded MultiSpecialtiy Hospital"/>
                    </div>
                    <div className="clientBox">
                    <ClientsCard title="Birla Hospital" desc="300 Beded MultiSpecialtiy Hospital"/>
                    </div>
                
                </div>
                <div>
                <div style={{marginBottom:"20px"}}> <OurPartners  text="Government Hospitals"/></div>

                    <div className="clientBox">
                    <ClientsCard title="King George Medical University" desc="Established in 1905 and has 4500 funcitonal beds"/>
                    </div>
                    <div className="clientBox">
                    <ClientsCard title="Sanjay Gandhi Post Graduate Institute" desc="Established in 1983, spread over 550 acres and has 1600+ beds"/>
                    </div>
                    <div className="clientBox">
                    <ClientsCard title="SM Medical College Agra" desc="Established in 1854 and has 950+ beds"/>
                    </div>
                    <div className="clientBox">
                    <ClientsCard title="GSVM Kanpur Hospital" desc="Established in 1956 and has 300+ beds"/>
                    </div>
                    
                
                </div>
            </div>
            <NewFooter/>
        </div>
    );
}

export default Clients;