import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import '../Styles/home.css';
import {useNavigate} from "react-router-dom";
function AboutCompany() {
  const navigate = useNavigate();
  function detailCompany(){
    navigate("/readMore");
  }
  return (
    <Card className="text-center-home" >
      <Card.Header className="ACheader" >About the Company</Card.Header>
      <Card.Body>
        <Card.Title className="ACTitle" >Sneh Communications</Card.Title>
        <Card.Text className="ACText">
          has been providing medical equipments, dignostics kits, radiology equipments, blood bank related products and services 
          to various private and government hospitals and dignostics centers around the states of Northern India
          <span style={{color:"blue",cursor:"pointer"}} onClick={detailCompany}>...Read More</span>
        </Card.Text>
        {/* <Button variant="primary">Go somewhere</Button> */}
      </Card.Body>
      {/* <Card.Footer className="text-muted">2 days ago</Card.Footer> */}
    </Card>
  );
}

export default AboutCompany;