import '../Styles/NewFooter.css';
import {useNavigate} from "react-router-dom";
const NewFooter = ()=>{
    var navigate = useNavigate();
    function about()
    {
        navigate("/about");
    }
    function clients()
    {
        navigate("/clients");
    }
    function contact()
    {
        navigate("/contact");
    } 
    return(
        <div className='MainFooterDiv'>
            <div className='insideDivFooter'>
                <h3 style={{fontWeight:"bold"}}>Quick Links - </h3>
            </div>
            <div className="footerMenu" style={{display:"flex",width:"60vw",justifyContent:"space-around",paddingBottom:"50px",color:"white"}}>
                <div className="" onClick={about} style={{marginLeft:"5px"}}>About Us</div>
                <div className="" onClick={clients} style={{marginLeft:"5px"}}>Clients</div>
                <div className="" onClick={contact} style={{marginLeft:"5px"}}>Contact Us</div>
            </div>
        </div>
    );
}

export default NewFooter;